import gql from "graphql-tag";

export const WalletOwner = gql`
  query WalletOwner {
    walletOwner {
      public_key
    }
  }
`;

export const CreateOrder = gql`
  mutation CreateOrder($createOrderInput: CreateOrderInput!) {
    createOrder(createOrderInput: $createOrderInput) {
      status
      info
    }
  }
`;
export const ClaimToken = gql`
  mutation Mutation($claimId: Int!) {
    claim(claimId: $claimId)
  }
`;

export const connectBeQuery = gql`
  mutation ConnectBE($data: String) {
    connectBE(data: $data)
  }
`;

export const getNodeKey = gql`
  query Query {
    nodeKey
  }
`;

export const checkPromo = gql`
  mutation PromotionInfo($getPromotionInfoInput: GetPromotionInfoInput) {
    promotionInfo(getPromotionInfoInput: $getPromotionInfoInput)
  }
`;

export const getAff = gql`
  query Affiliate {
    affiliate {
      affiliate
      socials
    }
    getClaimCommissionStatus
  }
`;
export const getAffChildren = gql`
  query Query {
    affiliateChildren
  }
`;

export const getAffHistory = gql`
  query AffiliateHistory {
    affiliateHistory {
      history
    }
  }
`;
