<template>
  <n-message-provider :placement="store.state.placement">
    <n-modal-provider>
      <div class="home_container">
        <div
          class="home_bg"
          :style="{ backgroundImage: `url('${getImage('sell_background')}')` }"
        >
          <HeaderComponent />
          <!-- <p  style="color: white;" target="_blank" id="where-am-i" @click="openEE">open</p> -->
          <div class="sell_section_container">
            <router-view></router-view>
          </div>
          <FloatMenu class="show_on_mobile" style="display: none" />
        </div>
      </div>
    </n-modal-provider>
  </n-message-provider>
</template>

<script lang="ts" setup>
import { NModalProvider, NMessageProvider } from "naive-ui";
import getImage from "./common/function/getImage";
import HeaderComponent from "./components/HeaderComponent.vue";
import store from "./store";
import {
  subscriptionClient,
  subscriptionClientNoToken,
} from "./graphql/client/user-client";
import { provideApolloClient, useSubscription } from "@vue/apollo-composable";
import { subscription, subscriptionKey } from "./graphql/query/subscription";
import { getCurrentInstance, onMounted, ref, watch } from "vue";
import { Emitter } from "mitt";
import { isDeviceLaptop, notify } from "./wallet/solana/Solana";
import FloatMenu from "./components/FloatMenu.vue";
import fetchClaimStatus from "./common/function/fetchClaimStatus";
import {
  getAffiliate,
  getAffiliateChildren,
  getAffiliateHistory,
} from "./common/function/SubscribeToListen";
const emitter: Emitter<any> =
  getCurrentInstance()?.appContext.app.config.globalProperties.emitter;

const { restart, result, stop, start } = provideApolloClient(
  subscriptionClient
)(() => useSubscription(subscription));

watch(result, (data) => {
  console.log(
    "🚀 ~ trigger new transaction >>>>>>>>>>>>>>>>>>>>>:",
    data?.event
  );
  if (data?.event?.order) {
    emitter.emit("purchare_key", data?.event?.order);
  }
  if (data?.event?.commission && data?.event?.commission?.status == "success") {
    notify("You have new commisson", "success");
    emitter.emit("commission", data?.event?.commission);

    fetchClaimStatus();
    getAffiliate();
    getAffiliateChildren();
    getAffiliateHistory();
  }
});

watch(
  () => store.state.user,
  (newVal, oldVal) => {
    console.log("change user");
    console.log("🚀 ~   :", newVal);

    if (newVal?.token && !oldVal?.token) {
      console.log("🚀 ~ restart:", newVal);
      if (isDeviceLaptop()) {
        restart();
      } else {
        setTimeout(() => {
          restart();
        }, 1500);
      }
      return;
    }
    if (newVal?.token && oldVal?.token && newVal?.token !== oldVal?.token) {
      console.log("change ws");
      stop();
      setTimeout(() => {
        start();
      }, 2000);
    }
  }
);
const openEE = async () => {
  window.open("https://google.com", "_top");
  // require('electron').shell.openExternal("https://google.com")
};
onMounted(() => {
  function detectBrowser() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    let browserInfo = { isInApp: false, browserName: "" };

    // Detect browsers (This is not exhaustive and might need updates based on new user agents)
    if (
      userAgent.includes("safari") &&
      !userAgent.includes("crios") &&
      !userAgent.includes("fxios")
    ) {
      browserInfo.browserName = "Safari";
    } else if (userAgent.includes("crios")) {
      browserInfo.browserName = "Chrome for iOS";
    } else if (userAgent.includes("fxios")) {
      browserInfo.browserName = "Firefox for iOS";
    } else if (userAgent.includes("instagram")) {
      browserInfo.browserName = "Instagram In-App";
      browserInfo.isInApp = true;
    } else if (userAgent.includes("facebook") || userAgent.includes("fb")) {
      browserInfo.browserName = "Facebook In-App";
      browserInfo.isInApp = true;
    } else if (userAgent.includes("twitter")) {
      browserInfo.browserName = "Twitter In-App";
      browserInfo.isInApp = true;
    } else if (userAgent.includes("snapchat")) {
      browserInfo.browserName = "Snapchat In-App";
      browserInfo.isInApp = true;
    } else if (userAgent.includes("linkedin")) {
      browserInfo.browserName = "LinkedIn In-App";
      browserInfo.isInApp = true;
    }

    // Additional checks for in-app scenarios
    if (
      (window.navigator as any).standalone ||
      window.matchMedia("(display-mode: standalone)").matches
    ) {
      // This could indicate the site is added to the home screen on iOS or running in PWA mode.
      browserInfo.isInApp = true;
      browserInfo.browserName = browserInfo.browserName
        ? browserInfo.browserName
        : "Standalone/WebApp";
    }

    return browserInfo;
  }

  const browserInfo = detectBrowser();
  console.log(browserInfo);
  // alert(JSON.stringify(browserInfo,null,2))

  if (browserInfo.isInApp) {
    // Customize behavior for in-app browsers
    console.log(
      "This site is being viewed in an in-app browser:",
      browserInfo.browserName
    );
    // alert('This site is being viewed in an in-app browser:' + browserInfo.browserName)
  } else {
    // Behavior for standalone browsers
    console.log(
      "This site is being viewed in a standalone browser:",
      browserInfo.browserName
    );
    // alert('This site is being viewed in a standalone browser:' + browserInfo.browserName)
  }
});
</script>

<style scoped>
.home_container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #15121d;
}

.home_bg {
  width: 100%;
  height: 100%;
  background: lightgray 50% / cover no-repeat;
  display: flex;
  flex-direction: column;
}

.sell_section_container {
  width: 100%;
  flex: 1;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .show_on_mobile {
    display: initial !important;
  }
}
</style>
