import store from "@/store";
import { NavItem } from "./type";
export const DEFAULT_REWARD_RANK = { rank: 0, rank_name: "newbie" };
export const MOBILE_BREAK_POINT = 1279;
export const USER_KEY = "user-information";
export const LOGGED_NAV = [
  {
    name: "BUY",
    href: "/",
  },
  {
    name: "HISTORY",
    href: "/history",
  },
  {
    name: "AFFILIATE",
    href: "/affiliate",
  },
  {
    name: "Buy",
    href: "#",
  },
  {
    name: "Referral",
    href: "#link",
  },
  {
    name: "History",
    href: "#history",
  },
  {
    name: "Hierarchy",
    href: "#hierarchy",
  },
  {
    name: "Contract",
    href: "#contract",
  },
];
export const NOT_LOGGED_NAV = [
  {
    name: "BUY",
    href: "/",
  },
  {
    name: "HISTORY",
    href: "/history",
  },
  {
    name: "AFFILIATE",
    href: "/affiliate",
  },
  {
    name: "Buy",
    href: "#",
  },
  {
    name: "Referral",
    href: "#link",
  },
  {
    name: "Contract",
    href: "#contract",
  },
];
