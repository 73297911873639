<template>
  <div class="connect_container">
    <div class="column_1">
      <SellInformationGroup
        v-for="(e, i) in sellInfoGroups"
        :key="i"
        :icon="e.icon"
        :title="e.title"
        :des="e.des"
      />
    </div>
    <div class="column_2">
      <div class="key_display">
        <KeyDisplay
          hidden-key-change
          :icon="'./favicon.png'"
          name="DISCORD ROLES & POINT"
          des="Key for submitting one claim to each SOGO Game Network"
        />
      </div>
      <div class="quantity_dropdown_group">
        <div class="choose_key_group">
          <div class="field_title">Key</div>
          <v-menu>
            <template v-slot:activator="{ props }">
              <div v-bind="props" class="key_selected_group cursor_pointer">
                <span class="key_selected_name">{{
                  (nodeSelected?.type
                    ? nodeSelected?.type?.toString().replaceAll("_", " ")
                    : "DISCORD ROLES & POINT"
                  ).toUpperCase()
                }}</span>
                <img
                  v-if="false"
                  :src="getIcon('arrow_bottom')"
                  alt=""
                  class="drop_icon"
                />
              </div>
            </template>
            <v-list v-if="false" class="content_class">
              <v-list-item
                @click="selectTypeKey(item)"
                v-for="(item, index) in listNodeKeys"
                :key="index"
                :value="index"
                class="key_item_list"
              >
                <v-list-item-title
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                  :class="{
                    font_weight_700: item.id == nodeSelected?.id,
                  }"
                  >{{
                    item.type.toString().replaceAll("_", " ")?.toUpperCase()
                  }}
                  <img
                    v-if="item.id == nodeSelected?.id"
                    :src="getIcon('checked')"
                    alt=""
                    style="width: 24px; height: 24px; aspect-ratio: 1"
                  />
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="form_quantity_group">
          <div class="quantity_group">
            <label for="quantityOfKey">Quantity</label>
            <div class="input_group">
              <span @click="decrement">-</span>
              <input
                id="quantityOfKey"
                type="number"
                :value="amountInput"
                @input="changeNumber"
              />
              <span @click="increment">+</span>
            </div>
          </div>
          <div v-if="messageValidateInput" class="message_validate">
            {{ messageValidateInput }}
          </div>
        </div>
      </div>
      <div class="key_count_price">
        <div class="key_info">
          <span class="field_title"
            >{{ amountInput }}x
            {{
              (nodeSelected?.type
                ? nodeSelected?.type?.toString().replaceAll("_", " ")
                : "DISCORD ROLES & POINT"
              ).toUpperCase()
            }}</span
          >

          <span>{{ store.state.priceBase }} SOL per key</span>
        </div>
        <div class="key_total_value">
          {{ formatterNumber(store.state.priceCurrent?.toFixed(9)) }} SOL
        </div>
      </div>
      <div class="promo_code_group">
        <label class="field_title" for="promoCode">Add promo code </label>
        <div class="input_promo_code_group">
          <input
            type="text"
            placeholder="Enter promo code"
            :value="code"
            @input="changeCode"
          />
          <GradientButton @click="applyPromotion" :disabled="!code"
            >APPLY</GradientButton
          >
        </div>
      </div>
      <div class="confirm_group">
        <div class="line_value">
          <span class="field_title">Promo Discount</span>
          <span class="discount_value"
            >{{ formatterNumber(priceDiscount?.toFixed(9)) }} SOL</span
          >
        </div>
        <div class="total_group">
          <span>Total</span>
          <div class="total_price">
            <span>{{ formatterNumber(priceFinal?.toFixed(9)) }} SOL</span>
          </div>
        </div>
        <GradientButton
        v-if="checkConnected()"
          :loading="loadingConfirm"
          :disabled="
            loadingConfirm ||
            amountInput < 1 ||
            messageValidateInput != '' ||
            !nodeSelected ||
            priceFinal < 0 ||
            !checkConnected()
          "
          @click="transfer"
          class="confirm_button"
        >
          CONFIRM
        </GradientButton>
        <div class="button" style="width: 100%;" v-if="!checkConnected()">
          <div class="nav_container" style="width: 100%;" >
            <div id="connect_button_package" style="width: 100%;">
              <WalletMultiButton
                v-if="isDeviceLaptop() && store.state?.solana"
              />
            </div>
            <div v-if="!isDeviceLaptop()" class="button_block">
              <GradientButton
                @click="handleClickConnectButton"
                class="connect_btn"
              >
                <div v-if="solana?.getSolanaStatus() !== 'CONNECTED'">
                  <WalletItem
                    name="Connect"
                    :icon="solana?.getWalletAdapter()?.icon"
                    :gap="4"
                  />
                </div>
                <div v-if="solana?.getSolanaStatus() == 'CONNECTED'">
                  <WalletItem
                    :name="solana?.getShortenedWalletAddress()"
                    :icon="getIcon('wallet')"
                    :gap="4"
                  />
                </div>
              </GradientButton>
            </div>
          </div>
          <n-modal v-model:show="solana.isOpenWalletModal">
            <CardContainer hidden-back-layer class="card_container_modal">
              <div
                class="wallet_item"
                v-for="(e, i) in solana?.getWalletAdapters()"
                :key="i"
                @click="
                  () => {
                    solana.connect(e);
                  }
                "
              >
                <WalletItem :name="e.adapter" :icon="e.icon" />
              </div>
            </CardContainer>
          </n-modal>
          <n-modal v-model:show="solana.isOpenOptionsModal">
            <CardContainer
              hidden-back-layer
              :displacement-level="0"
              class="card_container_modal"
            >
              <CopyText :value="solana.getWalletAddress() || ''">
                <WalletItem
                  :name="'Copy address'"
                  @click="() => solana?.closeOptionsModal()"
                />
              </CopyText>
              <WalletItem
                :name="'Disconnect'"
                @click="
                  () => {
                    solana?.closeOptionsModal();
                    solana?.disconnect();
                  }
                "
              />
            </CardContainer>
          </n-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import getIcon from "@/common/function/getIcon";
import SellInformationGroup from "./SellInformationGroup.vue";
import KeyDisplay from "@/components/KeyDisplay.vue";
import GradientButton from "./GradientButton.vue";
import { decryptPayload, isDeviceLaptop } from "@/wallet/solana/Solana";
import { getCurrentInstance, onMounted, onUnmounted, ref, watch } from "vue";
import store from "@/store";
import base58 from "bs58";
import { box, randomBytes } from "tweetnacl";
import useSolana from "@/hook/useSolana";
import { useMessage, NModal } from "naive-ui";
import {
  provideApolloClient,
  useMutation,
  useQuery,
  useSubscription,
} from "@vue/apollo-composable";
import { basicClient } from "@/graphql/client/basic-client";
import {
  CreateOrder,
  WalletOwner,
  checkPromo,
  getNodeKey,
} from "@/graphql/query/homeSellKey";
import {
  subscriptionClient,
  subscriptionClientNoToken,
  userClient,
} from "@/graphql/client/user-client";
import { useWallet, WalletMultiButton } from "solana-wallets-vue";
import "solana-wallets-vue/styles.css";
import { Connection, clusterApiUrl } from "@solana/web3.js";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { Emitter } from "mitt";
import ShowDemoNotification from "./ShowDemoNotification.vue";
import { amount } from "@metaplex-foundation/js";
import fetchClaimStatus from "@/common/function/fetchClaimStatus";
import getImage from "@/common/function/getImage";
import { stopSubscribeToBE } from "@/common/function/SubscribeToListen";
import { subscriptionKey } from "@/graphql/query/subscription";
import formatterNumber from "@/common/function/formatterNumber";
import CardContainer from "./CardContainer.vue";
import WalletItem from "./WalletItem.vue";
import CopyText from "./CopyText.vue";
const clusterApi: any = process.env.VUE_APP_RPC;
const clusterApiMobile: any = process.env.VUE_APP_RPC_MOBILE;

const handleClickConnectButton = () => {
  if (isDeviceLaptop()) {
    // solana.connectWebsite();
  } else {
    if (solana.getSolanaStatus() != "CONNECTED") {
      solana.openWalletModal();
      return;
    }
    if (solana.getSolanaStatus() == "CONNECTED") {
      solana.openOptionsModal();
      return;
    }
    // if (solana.getSolanaStatus() == "SELECTED_WALLET") {
    //   console.log("connecting");
    //   solana.connect();
    //   return;
    // }
  }
  // solana.openWalletModal();
};

const notify = (
  content: string,
  type: "success" | "error" | "loading" | "warning",
  duration = 2000
) => {
  toast(content, {
    theme: "dark",
    type: type,
    autoClose: duration,
    transition: "slide",
    dangerouslyHTMLString: true,
  });
};
interface SellKeyBoardProps {
  data: any;
}
const sellInfoGroups = [
  {
    icon: getIcon("key"),
    title: "Purchase keys",
    des: "The more keys you own, the more is SOGO you will earn",
  },
  {
    icon: getIcon("link"),
    title: "Keep your node running",
    des: "Your node will connect you to SOGO Games Network",
  },
  {
    icon: getIcon("link"),
    title: "Earn reward is SOGO",
    des: "Overtime you will earn is SOGO from SOGO Games Network",
  },
];

const solana = useSolana();
const sessionSolana = ref<any>(null);
const input = ref(null);
const balance = ref(0);
const amountInput = ref(1);
const loadingConfirm = ref<boolean>(false);
const timeoutDisconnectSecond = ref(60 * 15); //seconds
const message = useMessage();
const listNodeKeys = ref<any[]>([]);
const nodeSelected = ref<any>(null);
const connection = new Connection(clusterApi, "confirmed");
const code = ref<string>("");
const priceFinal = ref<number>(0);
const promotionInfo = ref<any>(null);
const priceDiscount = ref<number>(0);
const showDiscount = ref<boolean>(false);
const messageValidateInput = ref<string>("");
const changeCode = (e: any) => {
  code.value = e.target.value;
};

const applyPromotion = async (isCheckTransfer = false) => {
  if (isCheckTransfer && !code.value) {
    // notify(`Before proceeding, you need to connect your wallet.`, "warning");
    return;
  }

  if (!code.value) {
    notify("Code empty", "error");
    return;
  }
  try {
    const { mutate } = provideApolloClient(userClient)(() =>
      useMutation(checkPromo, {
        variables: {
          getPromotionInfoInput: {
            code: code.value,
          },
        },
      })
    );
    const res = await mutate();
    console.log("🚀 ~ applyPromotion ~ res:", res?.data?.promotionInfo);
    if (res?.data?.promotionInfo?.success) {
      showDiscount.value = true;

      promotionInfo.value = res?.data?.promotionInfo?.data;
      notify(`Apply success`, "success");
    } else {
      promotionInfo.value = null;
      notify(`${res?.data?.promotionInfo?.data?.msg}`, "error");
    }
    updateTotalPrice(amountInput.value);
  } catch (err) {
    notify("Promotion Not Found", "error");

    console.log("🚀 ~ applyPromotion ~ err:", err);

    console.log("🚀 ~ applyPromotion ~ err:", err);
  }
};

const emitter: Emitter<any> =
  getCurrentInstance()?.appContext.app.config.globalProperties.emitter;
//
const getOwnerAddress = () => {
  try {
    const { result, refetch, onResult } = provideApolloClient(basicClient)(() =>
      useQuery(WalletOwner)
    );
    onResult(() => {
      store.commit("setOwnerAddress", result.value?.walletOwner?.public_key);
    });
  } catch (err) {
    console.log("🚀 ~ getOwnerAddress ~ err:", err);
  }
};

const createOrder = async (
  keyType: string,
  quantity: number,
  transactionHash: string,
  walletAddress: string,
  code: string
) => {
  try {
    const data: any = {
      keyType,
      quantity,
      transactionHash,
      walletAddress,
    };

    if (code) {
      data.promotionCode = code;
    }
    const { mutate } = provideApolloClient(userClient)(() =>
      useMutation(CreateOrder, {
        variables: {
          createOrderInput: data,
        },
      })
    );
    const res = await mutate();
    console.log("🚀 ~ res:", res?.data.createOrder?.status);
    if (res?.data.createOrder?.status != "success") {
      notify(
        `${
          res?.data?.createOrder?.info.message ??
          "Create order faild! The system will recheck in a moment"
        }`,
        "error"
      );
    }
  } catch (err) {
    store.commit("setLog", { err });
    // message.error("Purchare error");
    notify("Purchare error", "success");

    console.log("🚀 ~ getClaim ~ err:", err);
  }
};

const {
  restart: restartKey,
  result: resultKey,
  stop: stopKey,
  start: startKey,
  onResult: onResultKey,
} = provideApolloClient(subscriptionClientNoToken)(() =>
  useSubscription(subscriptionKey)
);

onResultKey((data: any) => {
  // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>let's update key");
  // console.log(JSON.parse(JSON.stringify(nodeSelected.value)));
  // console.log(data);
  // console.log(data?.data?.typeKeyUpdate);
  if (data?.data?.typeKeyUpdate) {
    // const newKey = data?.data?.typeKeyUpdate && data?.data?.typeKeyUpdate[0];
    // const oldKey = JSON.parse(JSON.stringify(nodeSelected.value));
    // if (newKey && oldKey && newKey.id != oldKey?.id) {
    notify(
      `${nodeSelected.value?.type
        ?.toString()
        .replaceAll("_", " ")
        .toUpperCase()} has been sold out`,
      "warning",
      4000
    );
    // }
    getNodeKeys();
  }
});

const getNodeKeys = () => {
  try {
    const { result, refetch, onResult } = provideApolloClient(basicClient)(() =>
      useQuery(getNodeKey)
    );
    onResult(() => {
      console.log("result.value?.getNodeKey?.result", result.value?.nodeKey);

      listNodeKeys.value = result.value?.nodeKey;
      if (result.value?.nodeKey[0]) {
        nodeSelected.value = result.value?.nodeKey[0];
        store.commit("setBasePrice", Number(nodeSelected.value?.price_value));
        store.commit(
          "setCurrentPrice",
          amountInput.value * Number(nodeSelected.value?.price_value)
        );
        updateTotalPrice(amountInput.value);
      }
    });
    refetch();
  } catch (err) {
    console.log("🚀 ~ getNodeKeys ~ err:", err);
  }
};

const selectTypeKey = (item: any) => {
  console.log("🚀 ~ selectTypeKey ~ item:", item);
  try {
    nodeSelected.value = item;
    // amountInput.value = 1;
    store.commit("setBasePrice", Number(item?.price_value));
    store.commit("setCurrentPrice", 1 * Number(item?.price_value));
    updateTotalPrice(amountInput.value);
  } catch (err) {
    console.log("🚀 ~ selectTypeKey ~ err:", err);
  }
};

const changeNumber = (e: any) => {
  const number = e?.target?.value;
  // console.log("🚀 ~ changeNumber ~ numberrrr:", amountInput.value);
  amountInput.value = number;

  if (
    amountInput.value < 1 ||
    (amountInput.value + "").includes(",") ||
    (amountInput.value + "").includes(".")
  ) {
    messageValidateInput.value =
      "The quantity must be an integer greater than 0.";
    return;
  } else {
    messageValidateInput.value = "";
    if (amountInput.value > nodeSelected?.value?.quantity) {
      messageValidateInput.value = `Max Quantity ${nodeSelected?.value?.quantity}`;
    } else {
      messageValidateInput.value = "";
    }
    updateTotalPrice(amountInput.value);
  }

  if (amountInput.value >= 1) {
    const priceBase = store.state.priceBase;
    store.commit("setCurrentPrice", amountInput.value * priceBase);
    updateTotalPrice(amountInput.value);
  }
};

const checkSessionConnectMobile = () => {
  const timeConnect = window.localStorage.getItem("timeout_connect_mobile");
  console.log("🚀 ~ checkSessionConnectMobile ~ timeConnect:", timeConnect);
  if (timeConnect) {
    const time_connect_before = JSON.parse(timeConnect);
    const date_time_connect_before = new Date(time_connect_before);
    const now = new Date();
    const diff =
      (Math.abs(now.getTime()) - Math.abs(date_time_connect_before.getTime())) /
      1000;

    console.log("🚀 ~ checkSessionConnectMobile ~ diff:", diff);

    return diff > timeoutDisconnectSecond.value;
  }
  return false;
};

const transfer = async () => {
  if (!nodeSelected.value || !store.state.ownerAddress) {
    notify("Wait a minus, Select Key", "warning");
    return;
  }
  if (store.state.balanceTokenBase < priceFinal.value) {
    notify("Balance insufficient", "warning");
    return;
  }
  loadingConfirm.value = true;
  // await applyPromotion(true);
  if (isDeviceLaptop()) {
    await transferWeb();
  } else {
    const check = checkSessionConnectMobile();
    if (!check) {
      await transferMobile();
    } else {
      store.commit("setPlacement", "top-right");

      // message.warning("Session expired, disconnect now!", {
      //   duration: 1500,
      // });
      notify("Session expired, disconnect now!", "warning");
      setTimeout(() => {
        solana.disconnect();
      }, 1000);
    }
  }
  amountInput.value = 1;
  const priceBase = store.state.priceBase;
  promotionInfo.value = null;
  priceDiscount.value = 0;
  code.value = "";
  store.commit("setCurrentPrice", 1 * priceBase);
  updateTotalPrice(amountInput.value);
  loadingConfirm.value = false;
};

const updateTotalPrice = (number: number) => {
  amountInput.value = number;
  console.log("🚀 ~ changeNumber ~ number:", number);
  if (number > 0) {
    const priceBase = store.state.priceBase;
    priceFinal.value = number * priceBase;
    store.commit("setCurrentPrice", number * priceBase);
  }

  if (promotionInfo.value) {
    const priceCurrent = store.state.priceCurrent;

    const min_value = promotionInfo.value.min_value || 0;
    const max_value = promotionInfo.value.max_value || 999999999;
    let valueDiscount =
      (Number(priceCurrent) * Number(promotionInfo.value.percent_discount)) /
        100 +
      Number(promotionInfo.value.fix_discount);
    valueDiscount = Math.max(min_value, valueDiscount);
    valueDiscount = Math.min(max_value, valueDiscount);
    console.log("🚀 ~ updateTotalPrice ~ valueDiscount:", valueDiscount);
    priceDiscount.value = valueDiscount;
    priceFinal.value = priceCurrent - valueDiscount;
  }
};

const increment = () => {
  amountInput.value = Number(amountInput.value) + 1;
  updateTotalPrice(amountInput.value);
};

const decrement = () => {
  if (amountInput.value > 1) {
    amountInput.value = Number(amountInput.value) - 1;
    updateTotalPrice(amountInput.value);
  }
};

const transferWeb = async () => {
  try {
    const { publicKey } = useWallet();
    console.log("priceFinal", priceFinal.value);

    if (!store.state.ownerAddress) {
      notify("Server unavailable", "warning");
      return;
    }
    const transactionHash = await solana.transferWebsiteSellKey(
      store.state.ownerAddress,
      Number(priceFinal.value?.toFixed(9)),
      nodeSelected.value?.currency?.contract_address
    );
    console.log("🚀 ~ transferWeb ~ tx:", transactionHash);

    if (!transactionHash) {
      console.log("No transaction host");
      return;
    }
    // message.loading("Processing...", {
    //   duration: 15000,
    // });
    notify("Processing...", "loading", 15000);
    await connection.confirmTransaction(transactionHash, "finalized");

    if (!publicKey.value) return;
    await createOrder(
      nodeSelected.value.type,
      amountInput.value,
      transactionHash,
      publicKey.value?.toString(),
      code.value
    );
    updateTotalPrice(1);
  } catch (err) {
    // message.error("Confirm error");
    notify("Confirm error", "error");
    console.log("🚀 ~ transferWeb ~ err:", err);
  }
};

const transferMobile = async () => {
  try {
    console.log("mobile transfer");

    window.localStorage.setItem(
      "infoPurchare",
      JSON.stringify({
        type_key: nodeSelected?.value.type,
        amount_input: amountInput.value,
        promo: code.value,
      })
    );
    if (!store.state.ownerAddress) {
      notify("Server unavailable", "warning");
      return;
    }
    const url = await solana.transferMobileSellKey(
      store.state.ownerAddress,
      Number(priceFinal.value?.toFixed(9)),
      nodeSelected.value?.currency?.contract_address
    );
    if (!url) return;
    window.location.href = url?.toString();
    // setTimeout(() => {
    //   window.close();
    // }, 1000);
    return url;
  } catch (err) {
    console.log("🚀 ~ transferMobile ~ err:", err);
  }
};

const checkConnected = () => {
  let result = false;
  if (isDeviceLaptop()) {
    const { connected } = useWallet();
    console.log("🚀 ~ checkConnected ~ connected:", connected.value);
    result = connected.value;
  } else {
    result = solana.session ? true : false;
  }
  return result;
};

watch(promotionInfo, (newQuestion: any, oldQuestion: any) => {
  console.log("promotionInfo change");
  updateTotalPrice(amountInput.value);
});
watch(amountInput, (newQuestion: any, oldQuestion: any) => {
  //
});
onMounted(async () => {
  getNodeKeys();
  getOwnerAddress();
  emitter.on("purchare_key", async (data) => {
    console.log("🚀 ~ emitter.on ~ data:", data);
    if (data?.status == "success") {
      // message.success("Purchare Key Success");
      fetchClaimStatus();
      notify("Purchare Key Success", "success");
    } else {
      notify(
        "Create order faild! The system will recheck in a moment",
        "error"
      );
    }
  });
  if (!isDeviceLaptop()) {
    try {
      let urlParams = new URLSearchParams(window.location.search);
      const localItem: any | null = window.localStorage.getItem(
        "walletConnectSession"
      )
        ? JSON.parse(
            window.localStorage.getItem("walletConnectSession") as string
          )
        : null;
      if (localItem === null) {
        // alert("Please log in");
        return;
      }

      if (urlParams.has("data") && !urlParams.has("keyPairEncryptionPublic")) {
        const data = urlParams.get("data") as string;
        const nonce = urlParams.get("nonce") as string;

        const decryptedData: { transaction: string } = decryptPayload(
          data,
          nonce,
          base58.decode(localItem.sharedSecretDapp)
        );

        const tx = base58.decode(decryptedData.transaction);
        const transaction = window.localStorage.getItem("transaction")
          ? JSON.parse(window.localStorage.getItem("transaction") as string)
          : null;
        let transactionProcessed = false;
        if (!transaction) {
          window.localStorage.setItem(
            "transaction",
            JSON.stringify({ process: false, tx: base58.encode(tx) })
          );
        } else {
          // window.localStorage.removeItem("transaction");
          const trans = JSON.parse(JSON.stringify(transaction));
          if (trans.tx === base58.encode(tx) && trans.process === true) {
            transactionProcessed = true;
          }
        }
        if (!transactionProcessed) {
          const transactionHash = await solana.confirmTransaction(tx);
          updateTotalPrice(1);
          // message.loading("Processing...", {
          //   duration: 15000,
          // });
          notify("Processing...", "loading", 15000);
          await connection.confirmTransaction(transactionHash, "finalized");
          // alert(transactionHash)
          if (!solana.session?.public_key) {
            // message.error("No connect");
            notify("No connect", "error");
            return;
          }
          const localStorageInfoPurchare =
            window.localStorage.getItem("infoPurchare");
          const infoPurChare = localStorageInfoPurchare
            ? JSON.parse(localStorageInfoPurchare)
            : null;
          if (!infoPurChare) return;
          await createOrder(
            infoPurChare.type_key,
            infoPurChare.amount_input,
            transactionHash,
            solana.session?.public_key.toString(),
            infoPurChare.promo
          );
        }
      }
    } catch (err) {
      notify(`Transaction Faild`, "error");
      store.commit("setLog", { heeh: err });

      console.log("🚀 ~ onMounted ~ err:", err);
    }
  }
});

onMounted(() => {
  if (!isDeviceLaptop()) {
    try {
      const localItem: any | null = window.localStorage.getItem(
        "walletConnectSession"
      )
        ? JSON.parse(
            window.localStorage.getItem("walletConnectSession") as string
          )
        : null;

      if (store.state.sessionSolana === null && localItem !== null) {
        const decodedData = base58.decode(localItem.session);
        const bytesPayload = decodedData.slice(64);
        const payloadData: any = JSON.parse(
          Buffer.from(bytesPayload).toString("utf8")
        );
        sessionSolana.value = localItem;
        solana.setSession(localItem);
      } else {
        let urlParams = new URLSearchParams(window.location.search);
        const wallet: any = window.localStorage.getItem("walletAdapter")
          ? JSON.parse(window.localStorage.getItem("walletAdapter") as string)
          : null;
        if (!wallet) {
          return;
        }
        if (urlParams.has(wallet.key)) {
          const dapp_encryption_public_key = urlParams.get(
            wallet.key
          ) as string;
          const sharedSecretDapp = box.before(
            base58.decode(dapp_encryption_public_key),
            base58.decode(urlParams.get("keyPairEncryptionSecret") ?? "")
          );
          // dataRes.value = bs58.decode(data.nonce);
          const connectData = decryptPayload(
            urlParams.get("data") ?? "",
            urlParams.get("nonce") ?? "",
            sharedSecretDapp
          );
          window.localStorage.setItem(
            "walletConnectSession",
            JSON.stringify({
              ...connectData,
              sharedSecretDapp: base58.encode(sharedSecretDapp),
              keyPairEncryptionPublic: urlParams.get("keyPairEncryptionPublic"),
            })
          );
          const localItem = window.localStorage.getItem("walletConnectSession");
          sessionSolana.value =
            localItem !== null ? JSON.parse(localItem) : null;

          solana.setSession(localItem !== null ? JSON.parse(localItem) : null);
        }
      }
    } catch (error) {
      store.commit("setLog", { error });
    }
  }
});

onUnmounted(() => {
  emitter.off("purchare_key");
});
formatterNumber("4.545670000");
</script>

<style scoped>
.message_validate {
  display: flex;
  justify-content: end;
  color: red;
}

.form_quantity_group {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: end;
  width: 100%;
}

.connect_container {
  border-radius: 6px;
  border: 1px solid #fff;
  width: 100%;
  height: fit-content;
  max-width: 1160px;
  display: flex;
  align-items: stretch;
  margin: 80px 0;
  overflow: hidden;
}

.key_info span:nth-child(2) {
  color: #fff;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.key_info span:nth-child(1) {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 27px */
}

.key_total_value {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.key_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 29px;
}

/* .promo_code_group label {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
} */

.total_price {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
}

.promo_code_group {
  padding-bottom: 35px;
  border-bottom: 1px white solid;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 40px;
}

.input_promo_code_group input::placeholder {
  color: #a3a4a6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.confirm_group {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 12px;
}

.confirm_button {
  width: 100%;
}

.total_group span {
  color: #fff;

  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.total_group {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 40px;
}

.key_selected_name {
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 30px */
  white-space: nowrap;
}

.input_promo_code_group input {
  border-radius: 6px;
  flex: 1;
  border: 1px solid #a3a4a6;
  height: 40px;
  padding: 0px 16px;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  outline: none;
  max-width: 309px;
  flex: 1;
  min-width: 70px !important;
}

.key_selected_group:hover .drop_icon {
  transform: rotate(0deg);
}

.key_selected_group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.drop_icon {
  transition: 300ms;
  transform: rotate(180deg);
}

.choose_key_group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.input_promo_code_group {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  justify-content: end;
}

.card_container_modal {
  width: fit-content;
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.font_weight_700 {
  font-weight: 700;
}

.column_1 {
  display: flex;
  padding: 40px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;
  align-self: stretch;
  width: 24%;
  background: linear-gradient(270deg, #240b28 0%, #000 100%);
}

.key_count_price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px white solid;
  padding-bottom: 35px;
}

.line_value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.quantity_group label {
  color: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 16.5px */
}

.quantity_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
}

.column_2 {
  color: white;
  flex: 1;
  display: flex;
  padding: 32px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;

  border-left: 1px solid #fff;
  background: rgba(0, 0, 0, 0.82);
}

.content_class::-webkit-scrollbar {
  width: 4px;
}

.content_class {
  background: rgba(0, 0, 0, 1) !important;
  border: 1px white solid;
  margin-top: 16px;
  z-index: 10;
  min-width: 180px;
  max-height: 400px;
}

.key_display {
  border-bottom: 1px white solid;
  padding-bottom: 35px;
  width: 100%;
}

.quantity_dropdown_group {
  display: flex;
  width: 100%;
  border-bottom: 1px white solid;
  padding-bottom: 35px;
  align-items: center;
  justify-content: space-between;
}

.input_group span {
  font-size: 22px;
  font-weight: 700;
  padding: 0 24px;
  cursor: pointer;
}

.input_group span:hover {
  /* border: 1px white solid; */
}

.input_group {
  border-radius: 6px;
  border: 1px solid #fff;
  min-height: 76px;
  display: flex;
  align-items: center;
}

.input_group input {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 27px */
  width: 50px;
  outline: none;
  text-align: center;
}

.discount_value {
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 30px */
}

.field_title {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 27px */
  white-space: nowrap;
}

.key_item_list:hover {
  background: #777;
  transition: 300ms;
  padding-left: 20px;
  padding-right: 20px;
}

.key_item_list {
  color: white;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 1079px) {
  .connect_container {
    flex-direction: column-reverse;
  }

  .column_1 {
    width: 100%;
    align-items: center;
  }
}

@media (max-width: 880px) {
  .promo_code_group {
    flex-direction: column;
    align-items: start !important;
  }

  .input_promo_code_group {
    width: 100%;
  }

  .content_class {
    max-height: 320px;
  }

  .choose_key_group {
    flex-direction: row;
    gap: 16px;
    width: 100%;
    justify-content: space-between;
  }

  .quantity_dropdown_group {
    flex-direction: column;
    align-items: start;
    gap: 24px;
  }

  .quantity_group label,
  .field_title {
    color: #fff;
    font-family: Inter;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 24px */
  }

  .quantity_group {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
