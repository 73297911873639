import { createApp } from "vue";
import "./App.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import '@mdi/font/css/materialdesignicons.css'

import "@/variable.css";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import mitt from "mitt";
const emitter = mitt();
const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
  },
});

const app = createApp(App);

app.use(store);
app.use(router);
app.use(vuetify);
app.config.globalProperties.emitter = emitter;
app.mount("#app");
(() => {
  const urlParams = new URLSearchParams(window.location.search);
  // const campaignId = urlParams.get("campaign_id") ?? null;
  const affiliateId = urlParams.get("aff_id") ?? null;
  if (affiliateId) {
    // window.localStorage.setItem("campaign_id", campaignId);
    window.localStorage.setItem("aff_id", affiliateId);
  }
})();
