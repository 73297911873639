import { userClient } from "@/graphql/client/user-client";
import { getAff, getAffChildren, getAffHistory } from "@/graphql/query/homeSellKey";
import { SubscribeToListen, stopSubscribeToListen } from "@/graphql/query/subscription";
import store from "@/store";
import { provideApolloClient, useMutation, useQuery } from "@vue/apollo-composable";

export default async function SubscribeToBE(idsNode: number[]) {
    try {
        await stopSubscribeToBE();
        const { mutate } = provideApolloClient(userClient)(() =>
            useMutation(SubscribeToListen, {
                variables: {
                    keyIds: idsNode,
                },
            })
        );
        const res = await mutate();
        console.log("🚀 ~ SubscribeToListen ~ res:", res)
    } catch (err) {
        console.log(err);
    }
}

export async function stopSubscribeToBE() {
    try {
        const { mutate } = provideApolloClient(userClient)(() =>
            useMutation(stopSubscribeToListen))
        const res = await mutate();
        console.log("🚀 ~ stopSubscribeToBE ~ res:", res)
    } catch (err) {
        console.log(err);
    }
}



export const getAffiliate = () => {
    try {
        const { result, refetch, onResult } = provideApolloClient(userClient)(() =>
            useQuery(getAff)
        );
        onResult(() => {
            console.log("🚀 ~ onResult ~ result.value?:", result.value);

            store.commit("setAff", {
                ...result.value?.affiliate?.affiliate?.data,
                socials: result.value?.affiliate?.socials,
            });
        });
        refetch();
    } catch (err) {
        console.log("🚀 ~ getOwnerAddress ~ err:", err);
    }
};
export const getAffiliateChildren = () => {
    try {
        const { result, refetch, onResult } = provideApolloClient(userClient)(() =>
            useQuery(getAffChildren)
        );
        onResult(() => {
            if (result.value) {
                console.log(
                    "🚀🚀🚀🚀🚀🚀result.value?.affiliateChildren",
                    result.value?.affiliateChildren?.data
                );
                store.commit("setAffChildren", result.value?.affiliateChildren?.data);
            }
        });
        refetch();
    } catch (err) {
        console.log("🚀 ~ getOwnerAddress ~ err:", err);
    }
};

export const getAffiliateHistory = () => {
    try {
        const { result, refetch, onResult } = provideApolloClient(userClient)(() =>
            useQuery(getAffHistory)
        );
        onResult(() => {
            if (result.value) {
                console.log("🚀 ~ getAffiliateHistory ~ result:", result?.value);
                store.commit("setAffHistory", result.value?.affiliateHistory?.history);
            }
        });
        refetch();
    } catch (err) {
        console.log("🚀 ~ getAffHistory ~ err:", err);
    }
};