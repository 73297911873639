<template>
  <div
    class="container"
    :class="{
      bg_white: bgColor == 'white',
      bg_orange_pastel: bgColor == 'orange-pastel',
      bg_purple_pizzazz: bgColor == 'purple-pizzazz',
      bg_saffron: bgColor == 'saffron',
      bg_black: bgColor == 'black',
      light_shadow: mode == 'light-shadow',
      hidden_back_layer: hiddenBackLayer,
      padding_top: paddingTop || paddingTop == 0,
      padding_right: paddingRight || paddingRight == 0,
      padding_bottom: paddingBottom || paddingBottom == 0,
      padding_left: paddingLeft || paddingLeft == 0,
    }"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { defineProps, withDefaults } from "vue";
export interface CardContainerProps {
  bgColor?: "white" | "orange-pastel" | "purple-pizzazz" | "saffron" | "black";
  mode?: "dark-shadow" | "light-shadow";
  displacementLevel?: number;
  paddingTop?: number;
  paddingRight?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  hiddenBackLayer?: boolean;
}
const props = withDefaults(defineProps<CardContainerProps>(), {
  bgColor: "white",
  mode: "dark-shadow",
  displacementLevel: 12,
});
const displacement = `${props.displacementLevel}px`;
</script>

<style scoped>
.container {
  border-radius: 1rem;
  border: 2px solid var(--app-questn-com-black, #000);
  background: var(--orange-pastel);
  position: relative;
  width: 100%;
  padding: 24px;
}
.padding_top {
  padding-top: v-bind(paddingTop + "px") !important;
}
.padding_right {
  padding-right: v-bind(paddingRight + "px") !important;
}
.padding_bottom {
  padding-bottom: v-bind(paddingBottom + "px") !important;
}
.padding_left {
  padding-left: v-bind(paddingLeft + "px") !important;
}
.container::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: inherit;
  transform: translate(v-bind(displacement), v-bind(displacement));
  background: var(--app-questn-com-cloud-burst, #213147);
}
.light_shadow::after {
  background-color: var(--app-questn-com-nero, #fff);
  border: 2px solid var(--app-questn-com-black, #000);
}
.hidden_back_layer::after {
  display: none !important;
}
.bg_white {
  background-color: var(--app-questn-com-nero, #fff);
}
.bg_orange_pastel {
  background-color: var(--orange-pastel);
}
.bg_purple_pizzazz {
  background-color: var(--app-questn-com-purple-pizzazz);
}
.bg_saffron {
  background-color: var(--app-questn-com-saffron);
}
.bg_black {
  background-color: var(--app-questn-com-black);
}
@media (max-width: 1279px) {
  .container {
    padding: 16px;
  }
}
</style>
