<template>
  <header class="header">
    <div class="header_content">
      <a class="logo_header" href="https://solanagame.co/">
        <img :src="getIcon('logo')" alt="" class="logo" />
        <span>Home</span>
      </a>
      <nav class="menu">
        <router-link to="/"><span>Buy</span></router-link>
        <router-link to="/history"><span>History</span></router-link>
        <router-link to="/affiliate"><span>Affiliate</span></router-link>
      </nav>
      <div class="button">
        <div class="nav_container">
          <div id="connect_button_package">
            <WalletMultiButton v-if="isDeviceLaptop() && store.state?.solana" />
          </div>
          <div v-if="!isDeviceLaptop()" class="button_block">
            <!-- <PrimaryButton>
              <img :src="getIcon('mobile')" alt="" class="icon_button" />
            </PrimaryButton>
            <PrimaryButton @click="() => solana.openWalletModal()">
              <img :src="getIcon('network')" alt="" class="icon_button" />
            </PrimaryButton> -->
            <!-- <GradientButton  v-if="solana?.getSolanaStatus() !== 'CONNECTED'" @click="handleSelectWallet">
              <img  :src="getIcon('walletv2')" alt="" class="icon_button" />
            </GradientButton> -->
            <GradientButton
              @click="handleClickConnectButton"
              class="connect_btn"
            >
              <!-- <div
                class="text"
                v-if="
                  solana?.getSolanaStatus() == 'NO_WALLET'
                "
              >
                <img :src="getIcon('add')" alt="" class="icon_button" />
                <p>Connect</p>
              </div> -->
              <div v-if="solana?.getSolanaStatus() !== 'CONNECTED'">
                <WalletItem
                  name="Connect"
                  :icon="solana?.getWalletAdapter()?.icon"
                  :gap="4"
                />
              </div>
              <div v-if="solana?.getSolanaStatus() == 'CONNECTED'">
                <WalletItem
                  :name="solana?.getShortenedWalletAddress()"
                  :icon="getIcon('wallet')"
                  :gap="4"
                />
              </div>
            </GradientButton>
          </div>
        </div>
        <n-modal v-model:show="solana.isOpenWalletModal">
          <CardContainer hidden-back-layer class="card_container_modal">
            <div
              class="wallet_item"
              v-for="(e, i) in solana?.getWalletAdapters()"
              :key="i"
              @click="
                () => {
                  solana.connect(e);
                }
              "
            >
              <WalletItem :name="e.adapter" :icon="e.icon" />
            </div>
          </CardContainer>
        </n-modal>
        <n-modal v-model:show="solana.isOpenOptionsModal">
          <CardContainer
            hidden-back-layer
            :displacement-level="0"
            class="card_container_modal"
          >
            <CopyText :value="solana.getWalletAddress() || ''">
              <WalletItem
                :name="'Copy address'"
                @click="() => solana?.closeOptionsModal()"
              />
            </CopyText>
            <WalletItem
              :name="'Disconnect'"
              @click="
                () => {
                  solana?.closeOptionsModal();
                  solana?.disconnect();
                }
              "
            />
          </CardContainer>
        </n-modal>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import getIcon from "@/common/function/getIcon";
import GradientButton from "./GradientButton.vue";
import PrimaryLogo from "./PrimaryLogo.vue";
import NavItem from "./NavItem.vue";
import { LOGGED_NAV } from "@/common/const";
import { NOT_LOGGED_NAV } from "@/common/const";
import PrimaryButton from "./PrimaryButton.vue";
import { NAV_BAR_HEIGHT } from "@/ui_config";
import { MAX_WIDTH } from "@/ui_config";
import useSolana from "@/hook/useSolana";
import { NModal } from "naive-ui";
import CardContainer from "./CardContainer.vue";
import WalletItem from "./WalletItem.vue";
import CopyText from "./CopyText.vue";
import { isDeviceLaptop } from "@/wallet/solana/Solana";
import store from "@/store";
import { WalletMultiButton } from "solana-wallets-vue";
import "solana-wallets-vue/styles.css";
import getImage from "@/common/function/getImage";
const solana = useSolana();
const handleClickConnectButton = () => {
  if (isDeviceLaptop()) {
    // solana.connectWebsite();
  } else {
    if (solana.getSolanaStatus() != "CONNECTED") {
      solana.openWalletModal();
      return;
    }
    if (solana.getSolanaStatus() == "CONNECTED") {
      solana.openOptionsModal();
      return;
    }
    // if (solana.getSolanaStatus() == "SELECTED_WALLET") {
    //   console.log("connecting");
    //   solana.connect();
    //   return;
    // }
  }
  // solana.openWalletModal();
};

const handleSelectWallet = () => {
  solana.openWalletModal();
};
</script>

<style scoped>
.icon_button {
  width: 24px;
  height: 24px;
}
.logo_header span:hover {
  box-shadow: 0px 0px 20px #fff !important;
}
.logo_header span {
  cursor: pointer;
  color: white !important;
  text-decoration: none;
  padding: 20px;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 1.125;
  background: transparent;
  border-radius: 16px;
  margin-left: 48px;
}
.logo_header {
  display: flex;
  gap: 16px;
  align-items: center;
}
.btn-grad {
  width: 100%;
  box-shadow: 0px 0px 50px #fff !important;
  position: relative;
  display: flex;
  padding: 11px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background-image: linear-gradient(
    to right,
    #00c6ff 0%,
    #0072ff 51%,
    #00c6ff 100%
  );
  color: #000;
  background-size: 200% auto;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: 300ms;
}

.btn-grad:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

.header {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}
.header_content {
  display: flex;
  width: 100%;
  max-width: 1440px;
  padding: 24px 120px 24px 120px;
  justify-content: space-between;
  align-items: center;
}
.card_container_modal {
  width: fit-content;
  gap: 16px;
  display: flex;
  flex-direction: column;
}
.logo {
  width: 180px;
  /* height: 90px;
  flex-shrink: 0;
  border-radius: 352.5px; */
  display: block;
}
.menu {
  margin-left: 16px;
  color: white;
  margin-right: auto;
  display: flex;
  align-items: center;
  gap: 16px;
}
.button_block {
  display: flex;
  align-items: center;
  gap: 8px;
}
.menu span:hover {
  box-shadow: 0px 0px 20px #fff !important;
}
.menu span {
  cursor: pointer;
  color: white !important;
  text-decoration: none;
  padding: 20px;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 1.125;
  background: transparent;
  border-radius: 16px;
}

.connect_btn .text {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 19.2px */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.button {
}
@media (max-width: 1000px) {
  .header_content {
    padding: 16px;
    justify-content: space-between;
    align-items: center;
  }
  .logo_header span {
    margin-left: 16px;
  }
  .menu span,
  .logo_header span {
    font-size: 16px;
    padding: 8px;
  }
  .logo {
    width: 100px;
    height: 50px;
  }
}
@media (max-width: 600px) {
  .menu span {
    font-size: 12px;
    padding: 4px;
  }
  .menu {
    margin-left: 12px;
    display: none;
  }
  .logo {
    width: 80px;
    height: 42px;
  }
}
</style>
