import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  concat,
  HttpLink,
  split,
  createHttpLink,
} from "@apollo/client/core";
import { WebSocketLink } from '@apollo/client/link/ws';
export const BASE_URL = process.env.VUE_APP_BASE_URL;

// const testToken = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3YWxsZXRfYWRkcmVzcyI6IjRvS002aDNleXpkOUE2QnpkczNlYVlZWWZLUlVxTGtMTVVlalZucGlxQmlOIiwiaWF0IjoxNzEwNTczNzcxLCJleHAiOjE3MTA2NjAxNzF9.0UCk9paZdX2FBNDsOUkfNe0s25yAIiHTCUO8Udn2oXg";
const testToken = undefined;

import store from "@/store";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { getToken } from "@/service/statictis";
const httpLink = new HttpLink({
  uri: BASE_URL,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: testToken || (getToken()
        ? `Bearer ${getToken()}`
        : store?.state?.user?.token
          ? `Bearer ${store?.state?.user?.token ?? ""}`
          : "Bearer"),
    },
  });
  return forward(operation);
});
const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.VUE_APP_WSS as string,
    connectionParams() {
      return {
        Authorization: testToken || (store?.state?.user?.token
          ? `Bearer ${store?.state?.user?.token ?? ""}`
          : "Bearer"),
      };
    },
  })
);
const wsLinkNoToken = new GraphQLWsLink(
  createClient({
    url: process.env.VUE_APP_WSS as string,
  })
);
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);
const linkNoToken = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLinkNoToken,
  httpLink
);
export const userClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});

export const subscriptionClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export const subscriptionClientNoToken = new ApolloClient({
  link: linkNoToken,
  cache: new InMemoryCache(),
});




// const wsLinkNoToken = new GraphQLWsLink(
//   createClient({
//     url: process.env.VUE_APP_WSS as string,
//   })
// );

// const linkNoToken = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
//   },
//   wsLinkNoToken,
//   httpLink,
// );

// const subscriptionNoTokenClient = new ApolloClient({
//   linkNoToken,
//   cache: new InMemoryCache(),
// });