import fetchAllianceData from "@/common/function/fetchAllianceData";
import fetchClaimStatus from "@/common/function/fetchClaimStatus";
import Solana from "@/wallet/solana/Solana";
import { createStore } from "vuex";

interface User {
  token?: string;
}
interface Message {
  message: string;
  type?: string;
}
interface State {
  devLog?: any[];
  solana: Solana | undefined;
  // solana: Solana | undefined;
  historyData: any;
  rankLevelData: any[] | undefined;
  searchData: any[] | undefined;
  searchSelected: any;
  allianceData: any;
  rankSelected: any;
  isSearching: boolean;
  infoReward: any;
  poolInfo: any;
  sessionSolana: any;
  TVL: number | null;
  swapInfo: any | undefined;
  user: User;
  message: Message | undefined;
  showMessage: boolean;
  balanceTokenBase: number;
  bannerData?: any;
  priceCurrent: number;
  priceBase: number;
  placement: any,
  ownerAddress: string | null,
  userWalletAddress?: string,
  affiliate: any | null,
  affiliateHistory: any | null,
  affChildren: any
}

const store = createStore<State>({
  state: {
    devLog: [],
    solana: undefined,
    rankLevelData: undefined,
    allianceData: undefined,
    rankSelected: -1,
    isSearching: false,
    historyData: undefined,
    user: {
      token: undefined,
    },
    infoReward: {},
    poolInfo: {},
    sessionSolana: null,
    TVL: null,
    swapInfo: undefined,
    searchData: undefined,
    searchSelected: undefined,
    message: undefined,
    showMessage: false,
    balanceTokenBase: 0,
    bannerData: undefined,

    //
    priceCurrent: 0,
    priceBase: 0,
    placement: "top-right",
    ownerAddress: null,
    userWalletAddress: '',
    affiliate: null,
    affiliateHistory: null,
    affChildren: undefined
  },
  getters: {},
  mutations: {
    setSolana(state, data) {
      state.solana = data;
    },
    setUser(state, data) {
      state.user = data;
      if (state.user?.token) {
        fetchClaimStatus();
      }

    },
    setLog(state, data) {
      state.devLog = [...(state.devLog || []), data];
    },
    setRankLevelData(state, data) {
      state.rankLevelData = data;
    },
    setRankSelected(state, data) {
      state.rankSelected = data;
    },
    setIsSearching(state, data) {
      state.isSearching = data;
    },
    //
    setInfoReward(state, data) {
      state.infoReward = data;
    },
    setInfoPool(state, data) {
      state.poolInfo = data;
    },
    setSessionSolana(state, data) {
      state.sessionSolana = data;
    },
    setCurrentPrice(state, data) {
      state.priceCurrent = data;
    },
    setTVL(state, data) {
      state.TVL = data;
    },
    setSwapInfo(state, data) {
      state.swapInfo = data;
    },
    setSearchData(state, data) {
      state.searchData = data;
    },
    setAllianceData(state, data) {
      state.allianceData = data;
    },
    setSearchSelected(state, data) {
      state.searchSelected = data;
    },
    setMessage(state, data) {
      state.message = data;
    },
    showMessage(state) {
      state.showMessage = true;
    },
    closeMessage(state) {
      state.showMessage = false;
    },
    setBalanceTokenBase(state, data) {
      state.balanceTokenBase = data;
    },
    setBannerData(state, data) {
      state.bannerData = data;
    },
    setHistoryData(state, data) {
      if (state.user?.token) {
        console.log('set history data: ', data);
        state.historyData = data;
      } else {
        state.historyData = undefined;
      }
    },
    setPlacement(state, data) {
      state.placement = data;
    },
    setOwnerAddress(state, data) {
      state.ownerAddress = data;
    },
    setBasePrice(state, data) {
      state.priceBase = data;
    },
    setUserWalletAddress(state, data) {
      state.userWalletAddress = data;
    },
    setAff(state, data) {
      if (state.user?.token) {
        state.affiliate = data;
      } else {
        state.affiliate = undefined;
      }
    },
    setAffHistory(state, data) {
      if (state.user?.token) {
        state.affiliateHistory = data;
      } else {
        state.affiliateHistory = undefined;
      }
    },
    setAffChildren(state, data) {
      if (state.user?.token) {
        state.affChildren = data;
      } else {
        state.affChildren = undefined;
      }
    },

  },
  actions: {},
  modules: {},
});

export default store;
