<template>
  <d @click.stop="copy" class="cursor_pointer">
    <slot></slot>
  </d>
</template>

<script setup lang="ts">
import useTextMessage from "@/hook/useTextMessage";
import { defineProps } from "vue";
import { toast } from "vue3-toastify";
interface CopyText {
  value: string;
}
const props = defineProps<CopyText>();
const copy = async () => {
  try {
    window.navigator.clipboard.writeText(props.value);
    toast("Copied", {
      theme: "dark",
      type: "success",
      autoClose: 2000,
      transition: "slide",
      dangerouslyHTMLString: true,
      hideProgressBar: true,
    });
  } catch (err) {
    toast("Copied failed", {
      theme: "dark",
      type: "warning",
      autoClose: 200,
      transition: "slide",
      dangerouslyHTMLString: true,
      hideProgressBar: true,
    });
    console.log(err);
  }
};
</script>

<style scoped></style>
