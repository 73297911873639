import { provideApolloClient, useMutation } from "@vue/apollo-composable";
import store from "@/store";
import { connectBeQuery } from "@/graphql/query/homeSellKey";
import { userClient } from "@/graphql/client/user-client";
import { basicClient } from "@/graphql/client/basic-client";
import setUserToLocalStorage from "./setUserToLocalStorage";
import { encryptData } from "./encrypt";

export default async function login(input: any, onLogged?: () => any) {
  try {
    const affiliateId = window.localStorage.getItem("aff_id");
    console.log("🚀 ~ affiliate info:", affiliateId);
    window.localStorage.removeItem("aff_id");

    const data = await encryptData({
      wallet_address: input.wallet_address,
      aff_id: affiliateId,
    });

    const { mutate } = provideApolloClient(basicClient)(() =>
      useMutation(connectBeQuery, {
        variables: {
          data,
        },
      })
    );
    const res = await mutate();
    if (res?.data?.connectBE?.status == true) {
      const user: any = {
        token: res?.data?.connectBE?.access_token || "",
      };
      store.commit("setUser", user);
      setUserToLocalStorage(user);
      if (onLogged) {
        return await onLogged();
      }
    } else {
      //
    }
  } catch (err) {
    console.log(err);
  }
}
