<template>
  <div class="float_menu_container">
    <div class="menu_group">
      <FloatMenuItem
        class="menu_item"
        @click="handleClickMenu(e)"
        v-for="(e, i) in (store.state.user?.token
          ? LOGGED_NAV
          : NOT_LOGGED_NAV
        ).slice(0, 3)"
        :key="i"
        :href="e.href"
        :active="
          (hash == e.href || (e.href == '#home' && !hash)) && !showSubMenu
        "
        >{{ e.name }}</FloatMenuItem
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { LOGGED_NAV } from "@/common/const";
import { NOT_LOGGED_NAV } from "@/common/const";
import FloatMenuItem from "@/components/FloatMenuItem.vue";
import useTextMessage from "@/hook/useTextMessage";
import { ref } from "vue";
import { useStore } from "vuex";
const hash = ref("");
const showSubMenu = ref(false);
const store = useStore();
hash.value = window.location.hash;
const handleClickMenu = (e: any) => {
  hash.value = e?.href || "";
  showSubMenu.value = false;
};
const handleClickMore = (e: any) => {
  showSubMenu.value = !showSubMenu.value;
};

const getHeightBoard = (): string => {
  try {
    const height = `${
      (store.state.user?.token ? LOGGED_NAV : NOT_LOGGED_NAV).slice(3).length *
        26 +
      16 +
      ((store.state.user?.token ? LOGGED_NAV : NOT_LOGGED_NAV).slice(3).length -
        1) *
        8
    }px`;
    return height;
  } catch (err) {
    console.error(err);
    return "40vh";
  }
};
const getHeightBoardPadding = (): string => {
  try {
    const height = `${
      (store.state.user?.token ? LOGGED_NAV : NOT_LOGGED_NAV).slice(3).length *
        26 +
      16 +
      8 +
      ((store.state.user?.token ? LOGGED_NAV : NOT_LOGGED_NAV).slice(3).length -
        1) *
        8
    }px`;
    return height;
  } catch (err) {
    console.error(err);
    return "40vh";
  }
};
</script>

<style scoped>
.float_menu_container {
  display: flex;
  flex-direction: column;
  /* padding: 16px; */
  background: var(--app-questn-com-nero, #fff);
  background-color: transparent;
  width: 100%;
}
.sub_menu_group {
  width: fit-content;
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  border-radius: 12px;
  background: var(--app-questn-com-black, #000);
  background-color: transparent;
  margin-left: auto;
}
.sub_menu {
  position: absolute;
  right: 0px;
  transition: 300ms;
  overflow: hidden;
  bottom: calc(100%);
}
.hidden_sub_menu {
  height: 0px !important;
  opacity: 0.5;
}
.menu_group {
  position: relative;
  display: flex;
  padding: 16px 8px;
  justify-content: space-around;
  align-items: center;
  align-self: stretch;
  gap: 8px;

  border-radius: 12px;
  background: var(--app-questn-com-black, #000);
  background-color: transparent;
}
.box_shadow {
  box-shadow: 0px 0px 20px #fff !important;
}
.menu_item {
  /* flex: 1; */
}
</style>
