import gql from "graphql-tag";

const subscription = gql`
subscription Subscription {
  event
}
`;

const subscriptionKey = gql`
subscription Subscription {
  typeKeyUpdate
}
`;


const pointsUpdate = gql`
subscription Subscription {
  pointsUpdate
}
`;




const SubscribeToListen =
  gql`
mutation SubscribeToListen($keyIds: [Int]) {
  subscribeToListen(key_ids: $keyIds)
}
`;

const stopSubscribeToListen =
  gql`
mutation Mutation {
  stopUpdateLoop
}
`;
export { subscription, pointsUpdate, SubscribeToListen, stopSubscribeToListen, subscriptionKey };
