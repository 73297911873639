import { userClient } from "@/graphql/client/user-client";
import useSolana from "@/hook/useSolana";
import store from "@/store";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Connection, PublicKey, clusterApiUrl } from "@solana/web3.js";
import { provideApolloClient, useQuery } from "@vue/apollo-composable";
const clusterApi: any = process.env.VUE_APP_RPC;

export  const getSolBalance =async  (address: string, decimals = 9) =>{
  if (!address) return;
  try {
    const connection = new Connection(clusterApi, "confirmed");

    const balance = await connection.getBalance(
      new PublicKey(address)
    );
    console.log(balance / Math.pow(10, decimals));
    return balance / Math.pow(10, decimals);
  } catch (err) {
    console.log(err);
  }
}
export const getBalanceSplToken = async(mintToken: string, address: string)=>{
  if (!address) return [{ mintToken, tokenBalance: 0 }];
  const connection = new Connection(clusterApi, "confirmed");

  const filters = [
    {
      dataSize: 165, //size of account (bytes)
    },
    {
      memcmp: {
        offset: 32, //location of our query in the account (bytes)
        bytes: new PublicKey(address) as any, //our search criteria, a base58 encoded string
      },
    },
  ];
  if (mintToken) {
    filters.push({
      memcmp: {
        offset: 0, //number of bytes
        bytes: mintToken, //base58 encoded string
      },
    });
  }

  const accounts = await connection.getParsedProgramAccounts(
    TOKEN_PROGRAM_ID, //new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA")
    { filters: filters }
  );

  const infoTokens: any[] = [];

  accounts.forEach((account, i) => {
    //Parse the account data
    const parsedAccountInfo: any = account.account.data;
    const mintAddress = parsedAccountInfo["parsed"]["info"]["mint"];
    console.log("🚀 ~ accounts.forEach ~ mintAddress:", mintAddress);
    const tokenBalance =
      parsedAccountInfo["parsed"]["info"]["tokenAmount"]["uiAmount"];
    //Log results
    infoTokens.push({
      mintAddress,
      tokenBalance,
    });
  });

  return infoTokens;
}
export const getBlanceAccountConnect = async (address:string) => {
  try {
    console.log("get rate......")
    if (
      store.state.poolInfo?.mintA ==
      "So11111111111111111111111111111111111111112"
    ) {
      const balance = (await getSolBalance(address)) || 0;
      store.commit("setBalanceTokenBase", balance);
    } else {
      const balance = (
        await getBalanceSplToken(store.state.poolInfo?.mintA,address)
      )[0].tokenBalance;

      store.commit("setBalanceTokenBase", balance);

    }
    const balanceTokenQuote = (
      await getBalanceSplToken(store.state.poolInfo?.mintB,address)
    )[0].tokenBalance;
    store.commit("setBalanceTokenQuote", balanceTokenQuote);
  } catch (err) {
    console.log("🚀 ~ getBlanceAccountConnect ~ err:", err)

  }
}

export const  getToken = ()=>{
  const info =  window.localStorage.getItem("user-information");
  if(!info) return null;

  return JSON.parse(info).token;
}