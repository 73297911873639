import { userClient } from "@/graphql/client/user-client";
import store from "@/store";
import { provideApolloClient, useQuery } from "@vue/apollo-composable";
import { getClaimStatus } from "@/graphql/query/getClaimStatus";
import SubscribeToBE from "./SubscribeToListen";

export default function fetchClaimStatus() {
  try {
    const { result, refetch, onResult } = provideApolloClient(userClient)(() =>
      useQuery(getClaimStatus)
    );
    onResult(() => {

      if (result.value) {
        result.value = JSON.parse(JSON.stringify(result.value));
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.fetchClaimStatus', result.value);
        const historyData = result.value?.getClaims?.data;
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.historyData', result.value?.getClaims?.data);


        if (historyData) {
          store.commit("setHistoryData", historyData);
          // if (historyData && historyData.length > 0) {
          //   const data = historyData;
          //   const ids: number[] = data.map((item: any) => item.id)
          //   console.log("🚀 ~ onResult ~ ids:", ids);
          //   SubscribeToBE(ids);
          // }
        }
        //subcribe
        // if (result.value?.getClaims?.data.length > 0) {
        //   const data = result.value?.getClaims?.data;
        //   const ids: number[] = data?.map((item: any) => item.id)
        //   console.log("🚀 ~ onResult ~ ids:", ids);
        //   SubscribeToBE(ids)
        //   // SubscribeToBE([62,63])


        // }
      } else {
        console.log('>>>>>>>>>>>>>>>>>>> set history equal undefined');
        store.commit("setHistoryData", undefined);
      }
    });
    refetch();
  } catch (err) {
    console.log(err);
    store.commit("setHistoryData", []);

  }
}