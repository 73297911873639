const formatterNumber = (
  value: string,
): string => {
  try {
    // const temp = value.split('');
    // temp.reverse()
    // const index = temp.findIndex(item => item != '0')
    // let result = temp.slice(index).reverse().join().replaceAll(',', '');
    // if (result[result.length - 1] == '.') {
    //   result = result.slice(0, result.length - 1)
    // }
    return Number(value + '') + '';
  } catch (err) {
    console.error(err);
    return value;
  }
};

export default formatterNumber;
